import  PublicHeader  from './PublicHeader'
import ContactForm from './ContactForm'
import Footer from './Footer'

const ContactPage = () => {
    return (
        <>
            <PublicHeader/>
            <ContactForm/>
            <Footer/>
        </>
    )
}

export default ContactPage