import { useGetLinksQuery } from './linksApiSlice'
import { memo } from 'react'

const CustomerLink = ({ linkId }) => {

    const { link } = useGetLinksQuery("linksList", {
        selectFromResult: ({ data }) => ({
            link: data?.entities[linkId]
        }),
    })

    if (link) {
        const created = new Date(link.createdAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        const updated = new Date(link.updatedAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        return (
            <tr className="table__row even:bg-gray-50">
                
                <td className="table__cell note__created whitespace-nowrap px-3 py-4 text-sm text-gray-500">{link.title}</td>
                <td className="table__cell note__updated whitespace-nowrap px-3 py-4 text-sm text-gray-500"><a target="_blank" href={link.text} className="lien button text-blue-600 hover:text-slate-900">Accèder à synchroteam</a></td>
                <td className="table__cell note__status whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {link.completed
                        ? <span className="link__status--completed">Inactif</span>
                        : <span className="link__status--open">Actif</span>
                    }
                </td>
            </tr>
        )

    } else return null
}

const memoizedCustomerLink = memo(CustomerLink)

export default memoizedCustomerLink