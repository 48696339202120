import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewNoteMutation } from "./notesApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewNoteForm = ({ users }) => {

    const [addNewNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewNoteMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setText('')
            setUserId('')
            navigate('/dash/notes')
        }
    }, [isSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onTextChanged = e => setText(e.target.value)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, text, userId].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewNote({ user: userId, title, text })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !text ? "form__input--incomplete" : ''

    const content = (
        <>
            <form className="form grid grid-cols-1 gap-y-8" onSubmit={onSaveNoteClicked}>
                <div className="form__title-row">
                <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Nouveau message</h1>
                    <p className={errClass}>{error?.data?.message}</p>
                </div>
                
                <div className="form__action-buttons mt-2 flex justify-between">
                    <button
                        className="icon-button text-blue-600 hover:text-slate-900"
                        title="Save"
                        disabled={!canSave}
                    >
                        Sauvegarder <FontAwesomeIcon icon={faSave} />
                    </button>
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="title">
                        Titre:</label>
                    <input
                        className={`form__input ${validTitleClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="text">
                        Message:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="text"
                        name="text"
                        value={text}
                        onChange={onTextChanged}
                    />
                </div>
                <div>
                    <label className="form__label form__checkbox-container mb-3 block text-sm font-medium text-gray-700" htmlFor="username">
                        Assigné à:</label>
                    <select
                        id="username"
                        name="username"
                        className="form__select rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>
            </form>
        </>
    )

    return content
}

export default NewNoteForm