import { useGetUsersQuery } from "./usersApiSlice"
import User from './User'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const UsersList = () => {
    useTitle('Zelec: Liste d\'utilisateurs')

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {

        const { ids } = users

        const tableContent = ids?.length && ids.map(userId => <User key={userId} userId={userId} />)

        content = (
            <>
                <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Liste des utilisateurs</h1>
                <div className="overflow-x-auto">
                    <table className="table table--users min-w-full divide-y divide-gray-300">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th user__username table__th note__status py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Nom d'utilisateur</th>
                                <th scope="col" className="table__th user__roles px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Rôle</th>
                                <th scope="col" className="table__th user__edit relative py-3.5 pl-3 pr-4 sm:pr-3"><span className="sr-only">Modifier</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return content
}
export default UsersList