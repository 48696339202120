import { useParams } from 'react-router-dom'
import EditLinkForm from './EditLinkForm'
import { useGetLinksQuery } from './linksApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditLink = () => {
    useTitle('Zelec: Modifier lien')

    const { id } = useParams()

    const { username, isAdmin } = useAuth()

    const { link } = useGetLinksQuery("linksList", {
        selectFromResult: ({ data }) => ({
            link: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!link || !users?.length) return <PulseLoader color={"#FFF"} />


    if (!isAdmin) {
        if (link.username !== username) {
            return <p className="errmsg">Pas accès</p>
        }
    }

    const content = <EditLinkForm link={link} users={users} />

    return content
}
export default EditLink