import { useGetNotesQuery } from "./notesApiSlice"
import Note from "./Note"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const NotesList = () => {
    useTitle('Zelec: Liste de messages')

    const { username, isAdmin } = useAuth()

    const {
        data: notes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotesQuery('notesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = notes

        let filteredIds
        if (isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(noteId => entities[noteId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(noteId => <Note key={noteId} noteId={noteId} />)

        content = (
            <>
                <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Messages aux utilisateurs</h1>
                <div className="overflow-x-auto">
                    <table className="table table--notes min-w-full divide-y divide-gray-300">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th note__status py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">État</th>
                                <th scope="col" className="table__th note__created px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Créé le</th>
                                <th scope="col" className="table__th note__updated px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MAJ le</th>
                                <th scope="col" className="table__th note__title px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Titre</th>
                                <th scope="col" className="table__th note__username px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigné à</th>
                                <th scope="col" className="table__th note__edit relative py-3.5 pl-3 pr-4 sm:pr-3"><span className="sr-only">Modifier</span></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return content
}
export default NotesList