import { useState, useEffect } from "react"
import { useUpdateNoteMutation, useDeleteNoteMutation } from "./notesApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"

const EditNoteForm = ({ note, users }) => {

    const { isAdmin } = useAuth()

    const [updateNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateNoteMutation()

    const [deleteNote, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteNoteMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(note.title)
    const [text, setText] = useState(note.text)
    const [completed, setCompleted] = useState(note.completed)
    const [userId, setUserId] = useState(note.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setText('')
            setUserId('')
            navigate('/dash/notes')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onTextChanged = e => setText(e.target.value)
    const onCompletedChanged = e => setCompleted(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, text, userId].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        if (canSave) {
            await updateNote({ id: note.id, user: userId, title, text, completed })
        }
    }

    const onDeleteNoteClicked = async () => {
        await deleteNote({ id: note.id })
    }

    const created = new Date(note.createdAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(note.updatedAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !text ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isAdmin) {
        deleteButton = (
            <button
                className="icon-button icon-button text-blue-600 hover:text-slate-900"
                title="Delete"
                onClick={onDeleteNoteClicked}
            >
               Supprimer <FontAwesomeIcon icon={faTrashCan} />
            </button>
        )
    }

    const content = (
        <>
            <form className="form grid grid-cols-1 gap-y-8" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Éditer message #{note.ticket}</h1>
                </div>
                <p className={errClass}>{errContent}</p>
                <div className="form__action-buttons mt-2 flex justify-between">
                    <button
                        className="icon-button text-blue-600 hover:text-slate-900"
                        title="Save"
                        onClick={onSaveNoteClicked}
                        disabled={!canSave}
                    >
                        Sauvegarder <FontAwesomeIcon icon={faSave} />
                    </button>
                    {deleteButton}
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="note-title">
                        Titre:</label>
                    <input
                        className={`form__input ${validTitleClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="note-title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="note-text">
                        Message:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="note-text"
                        name="text"
                        value={text}
                        onChange={onTextChanged}
                    />
                </div>
                <div className="form__row">
                    <label className="form__label form__checkbox-container mb-3 block text-sm font-medium text-gray-700" htmlFor="note-completed">
                        Message à archiver:&nbsp;
                        <input
                            className="form__checkbox focus:ring-blue-500 sm:text-sm"
                            id="note-completed"
                            name="completed"
                            type="checkbox"
                            checked={completed}
                            onChange={onCompletedChanged}
                        />
                    </label>
                </div>
                <div>
                    <label className="form__label form__checkbox-container mb-3 block text-sm font-medium text-gray-700" htmlFor="note-username">
                        Message assigné à:</label>
                    <select
                        id="note-username"
                        name="username"
                        className="form__select border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>
                <div className="form__divider">
                    <p className="form__created mb-3 block text-sm font-medium text-gray-700">Créé le : {created}</p>
                    <p className="form__updated mb-3 block text-sm font-medium text-gray-700">Mis à jour le : {updated}</p>
                </div>
            </form>
        </>
    )

    return content
}

export default EditNoteForm