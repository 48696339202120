
import { useState, useEffect } from "react"
import { useUpdateNoteMutation, useDeleteNoteMutation } from "./notesApiSlice"
import { useGetNotesQuery } from './notesApiSlice'
import { memo } from 'react'

const CustomerNote = ({ noteId }) => {

    const { note } = useGetNotesQuery("notesList", {
        selectFromResult: ({ data }) => ({
            note: data?.entities[noteId]
        }),
    })

    const [updateNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateNoteMutation()


    const onCompletedChanged = async (e) => {
        await updateNote({ id: note.id, user: note.user, title: note.title, text: note.text, completed: !note.completed })
    }

    if (note) {
        const created = new Date(note.createdAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        const updated = new Date(note.updatedAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        return (
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow message-card mb-3">
                <div class="px-4 py-5 sm:px-6 bg-blue-100">
                    <h3 className="note__title">{note.title}</h3>
                </div>
                <div class="px-4 py-5 sm:p-6">
                    <p className="note__message">{note.text}</p>
                </div>
                <div class="px-4 py-4 sm:px-6 flex justify-between bg-gray-50">
                    <span className="note__created">{created}</span>
                    <div>
                    <label className="form__label form__checkbox-container" htmlFor="note-completed">
                    Archiver le message&nbsp;
                    <input
                        className="form__checkbox focus:ring-blue-500 sm:text-sm"
                        id="note-completed"
                        name="completed"
                        type="checkbox"
                        checked={note.completed}
                        onChange={onCompletedChanged}
                    />
                </label>
                    </div>
                </div>
            </div>
        )

    } else return null
}

const memoizedNote = memo(CustomerNote)

export default memoizedNote