import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/

const EditUserForm = ({ user }) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    const [username, setUsername] = useState(user.username)
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(user.roles)
    const [active, setActive] = useState(user.active)

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess || isDelSuccess) {
            setUsername('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onActiveChanged = () => setActive(prev => !prev)

    const onSaveUserClicked = async (e) => {
        if (password) {
            await updateUser({ id: user.id, username, password, roles, active })
        } else {
            await updateUser({ id: user.id, username, roles, active })
        }
    }

    const onDeleteUserClicked = async () => {
        await deleteUser({ id: user.id })
    }

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    let canSave
    if (password) {
        canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading
    } else {
        canSave = [roles.length, validUsername].every(Boolean) && !isLoading
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validUserClass = !validUsername ? 'form__input--incomplete' : ''
    const validPwdClass = password && !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <form className="form grid grid-cols-1 gap-y-8" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Modifier un utilisateur</h1>
                </div>
                <p className={errClass}>{errContent}</p>
                <div className="form__action-buttons mt-2 flex justify-between">
                    <button
                        className="icon-button text-blue-600 hover:text-slate-900"
                        title="Save"
                        onClick={onSaveUserClicked}
                        disabled={!canSave}
                    >
                        Sauvegarder <FontAwesomeIcon icon={faSave} />
                    </button>
                    <button
                        className="icon-button text-blue-600 hover:text-slate-900"
                        title="Delete"
                        onClick={onDeleteUserClicked}
                    >
                        Supprimer <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </div>
                
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="username">
                        Nom d'utilisateur: <span className="nowrap">[3-20 lettres]</span></label>
                    <input
                        className={`form__input ${validUserClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="off"
                        value={username}
                        onChange={onUsernameChanged}
                    />
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="password">
                        Mot de passe: <span className="nowrap">[vide = pas de changement]</span> <span className="nowrap">[4-12 caractères y compris !@#$%]</span></label>
                    <input
                        className={`form__input ${validPwdClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={onPasswordChanged}
                    />
                </div>
                <div>
                    <label className="form__label form__checkbox-container mb-3 block text-sm font-medium text-gray-700" htmlFor="user-active">
                        Actif:&nbsp;
                        <input
                            className="form__checkbox focus:ring-blue-500 sm:text-sm"
                            id="user-active"
                            name="user-active"
                            type="checkbox"
                            checked={active}
                            onChange={onActiveChanged}
                        />
                    </label>
                </div>
                <div>
                    <label className="form__label mb-3 block text-sm font-medium text-gray-700" htmlFor="roles">
                        Rôle assigné:</label>
                    <select
                        id="roles"
                        name="roles"
                        className={`form__select ${validRolesClass} block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm`}
                        multiple={true}
                        size="3"
                        value={roles}
                        onChange={onRolesChanged}
                    >
                        {options}
                    </select>
                </div>
            </form>
        </>
    )

    return content
}
export default EditUserForm