import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from './usersApiSlice'
import { memo } from 'react'

const User = ({ userId }) => {

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })

    const navigate = useNavigate()

    if (user) {
        const handleEdit = () => navigate(`/dash/users/${userId}`)

        const userRolesString = user.roles.toString().replaceAll(',', ', ')

        const cellStatus = user.active ? '' : 'table__cell--inactive'

        return (
            <tr className="table__row user even:bg-gray-50">
                <td className={`table__cell ${cellStatus} whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3`}>{user.username}</td>
                <td className={`table__cell ${cellStatus} whitespace-nowrap px-3 py-4 text-sm text-gray-500"`}>{userRolesString}</td>
                <td className={`table__cell ${cellStatus} whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3`}>
                    <button
                        className="icon-button table__button text-blue-600 hover:text-slate-900"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedUser = memo(User)

export default memoizedUser