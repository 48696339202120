import emailjs from "emailjs-com"
import { Form, Input, TextArea, Button } from "semantic-ui-react"
import Swal from "sweetalert2"

const ContactForm = () => {

    const SERVICE_ID = "service_uwpxpei"
    const TEMPLATE_ID = "template_g4toknm"
    const PUBLIC_KEY = "9_mqEjc79ItFh3Xjd"

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text)
            Swal.fire({
              icon: "success",
              title: "Merci, votre message a bien été envoyé !",
              customClass: {
                container:'fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in',
                popup: 'inset-x-0 mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in',
                title: 'text-slate-900 text-xl',
                icon: 'text-blue-600',
                confirmButton: 'group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600'
              },
              confirmButtonColor: 'rgb(37 99 235/var(--tw-bg-opacity))',
              position: 'center',
              iconColor: 'rgb(37 99 235/var(--tw-bg-opacity))'
            })
          }, (error) => {
            console.log(error.text)
            Swal.fire({
              icon: "error",
              title: "Navrés, une erreur s'est produite, écrivez-nous à zelec75015@orange.fr",
              text: error.text,
            })
          });
        e.target.reset()
      }
        return (
          <div id="contact" className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0 px-6 py-8">
            <h2 className="mt-10 text-xl font-semibold text-gray-900">Contactez-nous</h2>
            <p className="mt-2 text-sm text-gray-700">Pour toutes vos questions et demandes de devis.</p>
            <Form className="mt-10 grid grid-cols-1 gap-y-8" onSubmit={handleOnSubmit}>
              <div className="required field">
                  <label className="mb-3 block text-sm font-medium text-gray-700" htmlFor="form-input-control-email">Adresse e-mail</label>
                  <div className="ui left icon input">
                      <input className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm" name="user_email" placeholder="exemple@gmail.com" required id="form-input-control-email" type="text"/>
                  </div>
              </div>
              <div className="required field">
                  <label className="mb-3 block text-sm font-medium text-gray-700" htmlFor="form-input-control-phone">Numéro de téléphone</label>
                  <div className="ui left icon input">
                      <input className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm" name="user_phone" placeholder="06 12 34 56 78" required id="form-input-control-phone" type="text"/>
                  </div>
              </div>
              <div className="required field">
                  <label className="mb-3 block text-sm font-medium text-gray-700" htmlFor="form-input-control-last-name">Nom complet</label>
                  <div className="ui left icon input">
                      <input className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm" name="user_name" placeholder="Jeanne Dupont" required id="form-input-control-last-name" type="text"/>
                  </div>
              </div>
              <div className="required field">
                  <label className="mb-3 block text-sm font-medium text-gray-700" htmlFor="form-textarea-control-opinion">Message</label>
                  <textarea className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm" name="user_message" placeholder="Votre message ici" required id="form-textarea-control-opinion" rows="3"></textarea>
              </div>
              <Button className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600" type="submit" color="green">Envoyer</Button>
            </Form>
          </div>
        );
      }
export default ContactForm