import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import { CustomerLinksList } from '../links/LinksList'
import  CustomerNotesList  from '../notes/CustomerNotesList'
import ContactForm from '../../components/ContactForm.js'

const Welcome = () => {

    const { username, isAdmin } = useAuth()

    useTitle(`Zelec : ${username}`)

    const content = (
        <section>
            <div>

                <h1 className="font-display text-3xl tracking-tight text-slate-900">Bonjour <span className="text-blue-600">{username}</span> !</h1>

                {(!isAdmin) && <div className="customer-elements">
                    <CustomerNotesList /><CustomerLinksList /><ContactForm />
                    </div>}

                {(isAdmin) && <div className="admin-links">
                    <h3 className="mt-6 font-display text-xl text-slate-900">Messages aux utilisateurs</h3>
                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/notes">Voir les messages <span aria-hidden="true">→</span></Link></p>

                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/notes/new">Ajouter un message à un utilisateur <span aria-hidden="true">→</span></Link></p>
                    <h3 className="mt-6 font-display text-xl text-slate-900">Gestion des comptes utilisateurs</h3>
                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/users">Voir les utilisateurs <span aria-hidden="true">→</span></Link></p>

                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/users/new">Ajouter un nouvel utilisateur <span aria-hidden="true">→</span></Link></p>
                    <h3 className="mt-6 font-display text-xl text-slate-900">Gestion des ensembles d'immeubles</h3>
                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/links">Voir les ensembles d'immeubles <span aria-hidden="true">→</span></Link></p>

                    <p className="mt-2 mx-auto max-w-2xl text-md tracking-tight text-slate-700"><Link to="/dash/links/new">Ajouter un nouvel ensemble d'immeubles <span aria-hidden="true">→</span></Link></p>
                </div>}
            </div>

        </section>
    )

    return content
}
export default Welcome