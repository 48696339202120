import { Outlet } from 'react-router-dom'
import DashHeader from './DashHeader'

const DashLayout = () => {
    return (
        <div className="relative block sm:flex shrink-0 justify-center md:px-12 lg:px-0 full-page-dash-container overflow-hidden">
            
            <div className="global-dashboard-container">
                <DashHeader />
                <div className="dash-container relative z-10 flex flex-1 flex-col bg-white px-4 py-10 sm:justify-center md:flex-none md:px-28 sm:px-6">
                    <Outlet />
                </div>
            </div>
            <div className="hidden lg:relative lg:block lg:flex-1">
                <img alt="" loading="lazy" width="1664" height="1866" decoding="async" data-nimg="1" className="absolute inset-0 h-full w-full object-cover" src="/img/fond-login.jpg" style={{color: 'transparent'}}/>
            </div>
        </div>
    )
}
export default DashLayout