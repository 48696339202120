import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetLinksQuery } from './linksApiSlice'
import { memo } from 'react'

const Link = ({ linkId }) => {

    const { link } = useGetLinksQuery("linksList", {
        selectFromResult: ({ data }) => ({
            link: data?.entities[linkId]
        }),
    })

    const navigate = useNavigate()

    if (link) {
        const created = new Date(link.createdAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        const updated = new Date(link.updatedAt).toLocaleString('fr-FR', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/links/${linkId}`)

        return (
            <tr className="table__row even:bg-gray-50">
                <td className="table__cell note__status whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {link.completed
                        ? <span className="note__status--completed">Inactif</span>
                        : <span className="note__status--open">Actif</span>
                    }
                </td>
                <td className="table__cell note__created whitespace-nowrap px-3 py-4 text-sm text-gray-500">{created}</td>
                <td className="table__cell note__updated whitespace-nowrap px-3 py-4 text-sm text-gray-500">{updated}</td>
                <td className="table__cell note__title whitespace-nowrap px-3 py-4 text-sm text-gray-500">{link.title}</td>
                <td className="table__cell note__username whitespace-nowrap px-3 py-4 text-sm text-gray-500">{link.username}</td>

                <td className="table__cell relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <button
                        className="icon-button table__button text-blue-600 hover:text-slate-900"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedLink = memo(Link)

export default memoizedLink